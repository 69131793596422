.App {
    text-align: center;
}

/* Style the Image Used to Trigger the Modal */
img {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

/* img:hover {
  opacity: 0.7;
} */

/* The Modal (background) */
#image-viewer {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    object-fit: contain;
    /* max-width: 700px; */
}
.modal-content {
    animation-name: zoom;
    animation-duration: 0.6s;
}
@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
#image-viewer .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
#image-viewer .close:hover,
#image-viewer .close:focus {
    color: red;
    text-decoration: none;
    cursor: pointer;
}

.tracking-table-row-styles > td {
    padding: 5px 10px;
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }

    .css-mty9f0 {
        width: 350px;
    }

    .design-div {
        display: "flex";
        flex-wrap: "wrap";
        flex-direction: "column";
        align-items: "center";
    }

    .css-1q1u3t4-MuiTableRow-root {
        display: "math";
    }

    .cxt-id {
        max-width: "10px";
        width: "10px";
        word-wrap: "break-word";
    }

    .cxt-name {
        max-width: "10px";
        width: "10px";
        word-wrap: "break-word";
    }

    .cxt-likes {
        max-width: "10px";
        width: "75px";
        word-wrap: "break-word";
    }
}
